import i18n from 'i18next';
import Translation from '../../translations/Translation';
import fr from '../../translations/Fr';

export default function useMessages() {
  const { language } = i18n;
  let messages: Translation;
  switch (language) {
    case 'fr':
      messages = fr;
      break;
    default:
      messages = fr;
  }

  return messages;
}
