import axios, { AxiosResponse } from 'axios';
import { ProductType } from '../types/ProductType';
import axiosErrorHandler from '../lib/AxiosErrorHandler';
import { StrapiResponse } from '../types/StrapiTypes';

const ProductApi = {
  fetchProducts(): Promise<ProductType[]> {
    return axios.get('/api/products?populate=*&sort[0]=order')
      .then((response: AxiosResponse<StrapiResponse<ProductType[]>>) => response.data.data)
      .catch((err) => {
        throw axiosErrorHandler(err);
      });
  },
  fetchFirstProducts(): Promise<ProductType[]> {
    return axios.get('/api/products?populate=*&sort[0]=order&pagination[page]=1&pagination[pageSize]=4')
      .then((response: AxiosResponse<StrapiResponse<ProductType[]>>) => response.data.data)
      .catch((err) => {
        throw axiosErrorHandler(err);
      });
  },
  fetchProduct(id: string): Promise<ProductType> {
    return axios.get(`/api/products/${id}?populate=*`)
      .then((response: AxiosResponse<StrapiResponse<ProductType>>) => response.data.data)
      .catch((err) => {
        throw axiosErrorHandler(err);
      });
  },
};

export default ProductApi;
