import React from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import './App.css';
import theme from './theme/MaterialTheme';
import NotificationComponent from './services/notifications/notification.component';
import NotificationProvider from './services/notifications/notification.provider';
import Products from './pages/products/Products';
import Product from './pages/product/Product';
import Gallery from './pages/gallery/Gallery';
import Home from './pages/home/Home';
import NotFound from './pages/notFound/NotFound';
import About from './pages/about/About';

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <NotificationProvider>
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/product/:id" component={Product} />
            <Route exact path="/about" component={About} />
            <Route exact path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </Router>
        <NotificationComponent />
      </NotificationProvider>
    </MuiThemeProvider>
  );
}

export default App;
