import { useContext } from 'react';
import { NotificationContext } from './notification.provider';

function useNotifications() {
  const {
    notification, addError, addSuccess, addWarning, addInfo, removeNotification,
  } = useContext(NotificationContext);
  return {
    notification, addError, addSuccess, addWarning, addInfo, removeNotification,
  };
}

export default useNotifications;
