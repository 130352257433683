import useMessages from './hooks/Messages';

export default function httpErrorResolver(code: string) {
  const messages = useMessages();
  if (Object.keys(messages.errors.http).includes(code)) {
    // @ts-ignore
    return messages.errors.http[code];
  }
  return messages.errors.http['/error/default'];
}
