import React, { useState } from 'react';
import {
  Fade, Grid, makeStyles,
} from '@material-ui/core';
import { ProductType } from '../../../types/ProductType';
import { useOnComponentMounted } from '../../../lib/ReactHooksAlias';
import ProductApi from '../../../api/ProductApi';
import HomeLink from './HomeLink';
import ProductCard from '../../products/components/ProductCard';
import Loader from '../../../theme/components/Loader';
import SeeMoreLink from './SeeMoreLink';

const useStyle = makeStyles(() => ({
  root: {
    marginBottom: '80px',
  },
}));

export default function ProductsHomeTile() {
  const classes = useStyle();

  const [products, setProducts] = useState<ProductType[]>([]);

  useOnComponentMounted(() => {
    ProductApi.fetchFirstProducts().then((fetchedProducts: ProductType[]) => {
      setProducts(fetchedProducts);
    });
  });

  return (
    <div className={classes.root}>
      <HomeLink title="Créations" href="products" />
      {(products.length > 0)
        ? (
          <Grid container spacing={2}>
            {products.map((product: ProductType) => (
              <Fade in key={product.id} timeout={1000}>
                <Grid item xs={12} sm={6} md={3} xl={3}>
                  <ProductCard product={product} key={product.id} />
                </Grid>
              </Fade>
            ))}
          </Grid>
        )
        : <Loader />}
      <SeeMoreLink href="products" />
    </div>
  );
}
