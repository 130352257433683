import React from 'react';
import {
  makeStyles, StandardTextFieldProps, TextField, Theme, useTheme,
} from '@material-ui/core';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { Control, FieldError, useController } from 'react-hook-form';
import errorResolver from '../../services/ErrorResolver';

interface ThemeTextFieldProps extends StandardTextFieldProps {
  control: Control<any>;
  name: string;
  fieldError?: FieldError;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
}

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ThemeTextField({
  label, rows, fieldError, control, name, onChange, onBlur, rules,
}: ThemeTextFieldProps) {
  const classes = useStyle(useTheme());

  const { field } = useController({ control, name, rules });

  const onChangeCombined = (...args: any[]) => {
    field.onChange(...args);
    if (onChange) {
      onChange(args[0]);
    }
  };

  const onBlurCombined = (v: any) => {
    field.onBlur();
    if (onBlur) {
      onBlur(v);
    }
  };

  return (
    <TextField
      className={classes.root}
      value={field.value || ''}
      label={label}
      multiline={!!rows}
      rows={rows}
      error={!!fieldError}
      helperText={fieldError && errorResolver(fieldError.type, fieldError.message)}
      onBlur={onBlurCombined}
      onChange={onChangeCombined}
    />
  );
}
