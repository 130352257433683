import React, { useState } from 'react';
import {
  makeStyles, Theme, Typography, useTheme,
} from '@material-ui/core';
import MainLayout from '../../theme/layouts/MainLayout';
import AboutTile from './components/AboutTIle';
import { useOnComponentMounted } from '../../lib/ReactHooksAlias';
import { ArticleType } from '../../types/ArticleType';
import ArticleApi from '../../api/ArticleApi';
import useMessages from '../../services/hooks/Messages';

const useStyle = makeStyles((theme: Theme) => ({
  section: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(20),
  },
}));

export default function About() {
  const classes = useStyle(useTheme());
  const messages = useMessages();

  const [articles, setArticles] = useState<ArticleType[]>([]);

  useOnComponentMounted(() => {
    ArticleApi.fetchProducts()
      .then((result) => setArticles(result));
  });

  return (
    <MainLayout>
      <Typography variant="h2" component="h1">{messages.about.title}</Typography>
      <div className={classes.section}>
        {articles.map((article: ArticleType) => (
          <AboutTile article={article} />
        ))}
      </div>
    </MainLayout>
  );
}
