import React, { useMemo, useState } from 'react';
import { Fade, Grid, Typography } from '@material-ui/core';
import MainLayout from '../../theme/layouts/MainLayout';
import { useOnComponentMounted } from '../../lib/ReactHooksAlias';
import GalleryApi from '../../api/GalleryApi';
import GalleryItem from './components/GalleryItem';
import { GalleryItemType } from '../../types/GalleryType';
import useMessages from '../../services/hooks/Messages';

export default function Gallery() {
  const messages = useMessages();

  const [gallery, setGallery] = useState<GalleryItemType[]>([]);

  useOnComponentMounted(() => {
    GalleryApi.fetchGallery().then((fetchedGallery: GalleryItemType[]) => {
      setGallery(fetchedGallery);
    });
  });

  return useMemo(() => (
    <MainLayout>
      <Typography variant="h2" component="h1">{messages.gallery.title}</Typography>
      <Grid container spacing={1}>
        {
          gallery.map((galleryItem: GalleryItemType) => (
            <Fade in key={galleryItem.id}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <GalleryItem
                  galleryItem={galleryItem}
                />
              </Grid>
            </Fade>
          ))
        }
      </Grid>
    </MainLayout>
  ), [gallery]);
}
