import React, {
  ReactNode, useCallback, useRef,
} from 'react';
import {
  Collapse,
  IconButton, makeStyles, Theme, Typography, useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useOnComponentMounted, useOnComponentUnMounted } from '../../lib/ReactHooksAlias';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: ReactNode;
};

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    maxWidth: '1200px',
    width: '100%',
    margin: 'auto',
    padding: theme.spacing(2),
  },
}));

export default function ThemePopin({ open, setOpen, children }: Props) {
  const classes = useStyle(useTheme());

  const ref = useRef(null);

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!(ref.current! as any)?.contains(e.target)) {
        setOpen?.(false);
      }
    },
    [ref.current],
  );

  useOnComponentMounted(() => {
    document.addEventListener('click', clickListener, true);
  });
  useOnComponentUnMounted(() => {
    document.removeEventListener('click', clickListener, true);
  });

  return (
    <Collapse in={open} className={classes.root} ref={ref}>
      <div className={classes.top}>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.content}>
        {children}
      </div>
      <div className={classes.bottom}>
        <Typography>L&apos;éclairante</Typography>
      </div>
    </Collapse>
  );
}
