import React, { useMemo } from 'react';
import { makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useNotifications from './notification.hook';
import httpErrorResolver from '../HttpErrorResolver';
import SuccessResolver from '../SuccessResolver';

const useStyle = makeStyles(() => ({
  root: {
    border: 'none',
  },
}));

export default function NotificationComponent() {
  const classes = useStyle();
  const { notification, removeNotification } = useNotifications();

  const close = () => {
    removeNotification();
  };

  return useMemo(() => (
    <Snackbar
      open={notification?.active}
      autoHideDuration={3000}
      onClose={() => close()}
    >
      <Alert severity={notification?.type} className={classes.root}>
        {(notification?.type === 'error') && httpErrorResolver(notification.name)}
        {(notification?.type === 'success') && SuccessResolver(notification.name, notification.message)}
      </Alert>
    </Snackbar>
  ), [notification]);
}
