import axios, { AxiosResponse } from 'axios';
import { GalleryItemType } from '../types/GalleryType';
import axiosErrorHandler from '../lib/AxiosErrorHandler';
import { StrapiResponse } from '../types/StrapiTypes';

const GalleryApi = {
  fetchGallery(): Promise<GalleryItemType[]> {
    return axios.get('/api/gallery-items?populate=*&sort[0]=order')
      .then((response: AxiosResponse<StrapiResponse<GalleryItemType[]>>) => response.data.data)
      .catch((err) => {
        throw axiosErrorHandler(err);
      });
  },
  fetchFirstGalleryItems(): Promise<GalleryItemType[]> {
    return axios.get('/api/gallery-items?populate=*&sort[0]=order&pagination[page]=1&pagination[pageSize]=4')
      .then((response: AxiosResponse<StrapiResponse<GalleryItemType[]>>) => response.data.data)
      .catch((err) => {
        throw axiosErrorHandler(err);
      });
  },
};

export default GalleryApi;
