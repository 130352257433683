import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  Typography, useTheme,
} from '@material-ui/core';
import ThemePopin from '../../../theme/components/ThemePopin';
import ContactForm from '../../../theme/components/ContactForm';
import PicturePaper from '../../../theme/components/PicturePaper';
import ThemeButton from '../../../theme/components/ThemeButton';
import useMessages from '../../../services/hooks/Messages';

const useStyle = makeStyles((theme: Theme) => ({
  section: {
    minHeight: '40vh',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: '80px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  mainPicture: {
    width: '100%',
    flex: 3,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minHeight: '20vh',
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minHeight: '13vh',
      marginBottom: theme.spacing(8),
    },
  },
  empty: {
    flex: 1,
  },
  informations: {
    flex: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(8),
    verticalAlign: 'top',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 0,
      marginBottom: theme.spacing(8),
      height: 'unset',
    },
  },
  textSection: {
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  imgUrl: string;
};

export default function HomeTile({ imgUrl }: Props) {
  const classes = useStyle(useTheme());
  const [open, setOpen] = useState<boolean>(false);
  const messages = useMessages();

  return (
    <>
      <ThemePopin open={open} setOpen={setOpen}>
        <ContactForm onClose={() => setOpen(false)} />
      </ThemePopin>
      <div className={classes.section}>
        <PicturePaper className={classes.mainPicture} url={imgUrl} />
        <div className={classes.empty} />
        <div className={classes.informations}>
          <div className={classes.textSection}>
            <Typography variant="h2" component="h1">{messages.home.title}</Typography>
            <Typography variant="body1" component="p" paragraph>{messages.home.description1}</Typography>
            <Typography variant="body1" component="p" paragraph>{messages.home.description2}</Typography>
            <Typography variant="body1" component="p" paragraph>{messages.home.description3}</Typography>
            <Typography variant="body1" component="p">{messages.home.description4}</Typography>
          </div>
          <ThemeButton onClick={() => setOpen(true)} fullWidth>
            {messages.entities.contact.button}
          </ThemeButton>
        </div>
      </div>
    </>
  );
}
