import React from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(16),
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
    },
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    flex: '1',
    textAlign: 'left',
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    color: theme.palette.primary.main,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    width: '400px',
  },
}));

export default function ThemeNavBar() {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <header className={classes.root}>
      <Link
        to="/"
        className={classes.button}
        type="button"
      >
        <img className={classes.logo} src="/logos/logo-horizontal.png" alt="logo l\'eclairante" />
      </Link>
    </header>
  );
}
