import axios, { AxiosResponse } from 'axios';
import axiosErrorHandler from '../lib/AxiosErrorHandler';
import { StrapiResponse } from '../types/StrapiTypes';
import { ArticleType } from '../types/ArticleType';

const ProductApi = {
  fetchProducts(): Promise<ArticleType[]> {
    return axios.get('/api/articles?populate=*&sort[0]=order')
      .then((response: AxiosResponse<StrapiResponse<ArticleType[]>>) => response.data.data)
      .catch((err) => {
        throw axiosErrorHandler(err);
      });
  },
  fetchFirstsProducts(): Promise<ArticleType[]> {
    return axios.get('/api/articles?populate=*&sort[0]=order&pagination[page]=1&pagination[pageSize]=4')
      .then((response: AxiosResponse<StrapiResponse<ArticleType[]>>) => response.data.data)
      .catch((err) => {
        throw axiosErrorHandler(err);
      });
  },
};

export default ProductApi;
