import React from 'react';
import {
  Grid, makeStyles, Theme, Typography, useTheme,
} from '@material-ui/core';
import PicturePaper from '../../../theme/components/PicturePaper';
import { ArticleType } from '../../../types/ArticleType';

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    height: '50vh',
    [theme.breakpoints.down('md')]: {
      height: '40vw',
    },
    [theme.breakpoints.down('xs')]: {
      height: '70vw',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  pic: {
    height: '100%',
  },
}));

type Props = {
  article: ArticleType;
  inHome?: boolean;
};

export default function AboutTile({ article, inHome }: Props) {
  const classes = useStyle(useTheme());
  return (
    <Grid container spacing={8}>
      <Grid item xs={12} sm={6} className={classes.container}>
        <Grid container style={{ height: '100%' }} spacing={2}>
          {article.attributes.pictures.data?.map((picture) => (
            <Grid item xs={article.attributes.pictures.data?.length > 1 ? 6 : 8}>
              <PicturePaper url={picture.attributes.url} className={classes.pic} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <div>
          <Typography
            variant={inHome ? 'h4' : 'h3'}
            component={inHome ? 'h3' : 'h2'}
            gutterBottom
          >
            {article.attributes.title}
          </Typography>
          <Typography variant="body1" paragraph>
            {article.attributes.test}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
