import React from 'react';
import {
  makeStyles, Theme, Typography, useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PicturePaper from '../../../theme/components/PicturePaper';
import { ProductType } from '../../../types/ProductType';

type Props = {
  product: ProductType;
};

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    height: '400px',
  },
  title: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  link: {
    display: 'flex',
    height: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
    color: 'inherit',
    textDecoration: 'none',
  },
  info: {
    position: 'relative',
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    opacity: 0,
    transition: '.3s ease-in-out',
    '&:hover': {
      opacity: 1,
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2),
      opacity: 1,
    },
  },
  textSection: {
    padding: theme.spacing(2),
    backgroundColor: 'rgba(255, 255, 255, .7)',
    borderRadius: theme.spacing(2),
    flex: 0,
  },
  textSectionStatic: {
    padding: theme.spacing(2),
    backgroundColor: 'rgba(255, 255, 255, .7)',
    borderRadius: theme.spacing(2),
    flex: 1,
  },
  buttonSection: {
    display: 'flex',
    width: '100%',
  },
}));

export default function ProductCard({ product }: Props) {
  const classes = useStyle(useTheme());
  const isMiniPicturePresent = !!product.attributes.mainPicture;
  return (
    <PicturePaper
      className={classes.root}
      url={isMiniPicturePresent ? product.attributes.miniPicture.data.attributes.url : undefined}
      border
    >
      <Link to={`/product/${product.id}`} className={classes.link}>
        <div className={classes.info}>
          <div className={isMiniPicturePresent ? classes.textSection : classes.textSectionStatic}>
            <Typography variant="h5" className={classes.title}>
              {product.attributes.name}
            </Typography>
            <Typography variant="body2">
              {product.attributes.text}
            </Typography>
          </div>
        </div>
      </Link>
    </PicturePaper>
  );
}
