import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  Fade,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import MainLayout from '../../theme/layouts/MainLayout';
import ProductInfo from './components/ProductInfo';
import { useOnComponentMounted } from '../../lib/ReactHooksAlias';
import PicturePaper from '../../theme/components/PicturePaper';
import ThemePopin from '../../theme/components/ThemePopin';
import ContactForm from '../../theme/components/ContactForm';
import ProductApi from '../../api/ProductApi';
import Loader from '../../theme/components/Loader';
import { ProductType } from '../../types/ProductType';
import { Image } from '../../types/StrapiTypes';
import ThemeButton from '../../theme/components/ThemeButton';

const useStyle = makeStyles((theme: Theme) => ({
  paper: {
    height: '30vh',
    border: 'none',
  },
  mainPaper: {
    height: '35vh',
    border: 'none',
  },
  infoFirst: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  infoLast: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  editButton: {
    margin: `${theme.spacing(2)}px 0`,
  },
  backButton: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Product() {
  const classes = useStyle(useTheme());
  const [product, setProduct] = useState<ProductType>();
  const [open, setOpen] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  useOnComponentMounted(() => {
    ProductApi.fetchProduct(id).then((fetchedProduct: ProductType) => {
      setProduct(fetchedProduct);
    });
  });

  const back = () => {
    history.goBack();
  };

  return useMemo(
    () => (
      <MainLayout>
        {
          (product?.id)
            ? (
              <>
                <ThemePopin open={open} setOpen={setOpen}>
                  <ContactForm onClose={() => setOpen(false)} />
                </ThemePopin>
                <ThemeButton className={classes.backButton} onClick={back}>retour</ThemeButton>
                <Grid container spacing={10}>
                  <Grid className={classes.infoFirst} item xs={12} md={4}>
                    <Fade in={!!product}>
                      <ProductInfo product={product} setOpen={setOpen} />
                    </Fade>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} xl={8}>
                    <Grid container spacing={1}>
                      <Fade in={!!product}>
                        <Grid item xs={12}>
                          <PicturePaper
                            url={product.attributes.mainPicture.data.attributes.url}
                            className={classes.mainPaper}
                          />
                        </Grid>
                      </Fade>
                      {product.attributes.pictures.data.map((image: Image) => (
                        <Fade in={!!product}>
                          <Grid item xs={12} sm={6}>
                            <PicturePaper url={image.attributes.url} className={classes.paper} />
                          </Grid>
                        </Fade>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid className={classes.infoLast} item xs={12} sm={4}>
                    <Fade in={!!product}>
                      <ProductInfo product={product} setOpen={setOpen} />
                    </Fade>
                  </Grid>
                </Grid>
              </>
            )
            : <Loader />
        }
      </MainLayout>
    ),
    [open, product],
  );
}
