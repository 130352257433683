import { ApiError } from '../types/HttpErrorType';

export default function axiosErrorHandler(axiosResponse: any): ApiError {
  const error = axiosResponse?.response?.data?.error;
  if (error?.code) {
    return {
      status: error.status,
      code: error.code,
      title: error.title,
      detail: error.detail,
      fields: error.fields,
      name: error.code,
      message: error.title,
    };
  }
  return {
    status: 500,
    code: 'error/default',
    title: 'An unexpected error has occured',
    name: 'error/default',
    message: 'An unexpected error has occured',
  };
}
