import useMessages from './hooks/Messages';

export default function SuccessResolver(type: string, message?: string) {
  const messages = useMessages();
  if (Object.keys(messages.success).includes(type)) {
    // @ts-ignore
    if (Object.keys(messages.success[type]).includes(message)) {
      // @ts-ignore
      return messages.success[type][message];
    }
    // @ts-ignore
    return messages.success[type].default;
  }
  return messages.success.default;
}
