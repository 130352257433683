import React from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles, Theme,
  Typography, useTheme,
} from '@material-ui/core';

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    marginBottom: theme.spacing(8),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationThickness: '2px',
    },
  },
}));

type Props = {
  title: string;
  href: string;
};

export default function HomeLink({ title, href }: Props) {
  const classes = useStyle(useTheme());

  return (
    <div className={classes.container}>
      <Link to={href} className={classes.link}>
        <Typography variant="h3" component="h2">
          {title}
        </Typography>
      </Link>
    </div>
  );
}
