import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface Props extends ButtonProps {
  to?: string;
}

export default function ThemeButton({
  color, size, children, className, fullWidth, to, onClick, type,
}: Props) {
  const history = useHistory();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (to) history.push(to);
    else if (onClick) onClick(e);
  };

  return (
    <Button
      type={type || 'button'}
      fullWidth={fullWidth}
      variant="contained"
      color={color || 'primary'}
      size={size}
      disableElevation
      className={className}
      onClick={(e) => handleClick(e)}
    >
      {children}
    </Button>
  );
}
