import axios, { AxiosResponse } from 'axios';
import { ContactFormType } from '../types/ContactType';
import axiosErrorHandler from '../lib/AxiosErrorHandler';

const ContactApi = {
  postContact(contactForm: ContactFormType) {
    return axios.post('/api/contacts', { data: contactForm })
      .then((response: AxiosResponse<void>) => response.data)
      .catch((err) => {
        throw axiosErrorHandler(err);
      });
  },
};

export default ContactApi;
