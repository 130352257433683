import React, { useState } from 'react';
import {
  Fade, Grid, makeStyles,
} from '@material-ui/core';
import { useOnComponentMounted } from '../../../lib/ReactHooksAlias';
import HomeLink from './HomeLink';
import Loader from '../../../theme/components/Loader';
import SeeMoreLink from './SeeMoreLink';
import { GalleryItemType } from '../../../types/GalleryType';
import GalleryApi from '../../../api/GalleryApi';
import GalleryItem from '../../gallery/components/GalleryItem';

const useStyle = makeStyles(() => ({
  root: {
    marginBottom: '80px',
  },
}));

export default function GalleryHomeTile() {
  const classes = useStyle();

  const [gallery, setGallery] = useState<GalleryItemType[]>([]);

  useOnComponentMounted(() => {
    GalleryApi.fetchFirstGalleryItems().then((fetchedGallery: GalleryItemType[]) => {
      setGallery(fetchedGallery);
    });
  });

  return (
    <div className={classes.root}>
      <HomeLink title="Galerie" href="gallery" />
      {(gallery.length > 0)
        ? (
          <Grid container spacing={1}>
            {
              gallery.map((galleryItem: GalleryItemType) => (
                <Fade in key={galleryItem.id}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <GalleryItem
                      galleryItem={galleryItem}
                    />
                  </Grid>
                </Fade>
              ))
            }
          </Grid>
        )
        : <Loader />}
      <SeeMoreLink href="gallery" />
    </div>
  );
}
