import React from 'react';
import {
  makeStyles, Theme, Typography, useTheme,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import ThemeButton from './ThemeButton';
import { ContactFormType } from '../../types/ContactType';
import ThemeTextField from './ThemeTextField';
import useValidators from '../../services/Validators';
import ContactApi from '../../api/ContactApi';
import useNotifications from '../../services/notifications/notification.hook';
import { ApiError } from '../../types/HttpErrorType';

type Props = {
  onClose?: () => void
};

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default function ContactForm({ onClose }: Props) {
  const classes = useStyle(useTheme());

  const {
    control, handleSubmit, formState: { errors }, reset,
  } = useForm<ContactFormType>();
  const { isEmpty } = useValidators();
  const { addSuccess, addError } = useNotifications();

  const onSubmit = (contactForm: ContactFormType) => {
    ContactApi.postContact(contactForm)
      .then(() => {
        reset({});
        addSuccess('add', 'contact');
        if (onClose) onClose();
      })
      .catch((httpError: ApiError) => {
        addError(httpError.name, httpError.message);
      });
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h2">Contact</Typography>
      <Typography variant="caption" paragraph>Tel: 06 68 13 57 24</Typography>
      <ThemeTextField
        control={control}
        name="firstName"
        label="Prénom"
        fieldError={errors.firstName}
        rules={{
          required: true,
          validate: {
            isEmpty,
          },
        }}
      />
      <ThemeTextField
        control={control}
        label="Nom"
        name="lastName"
        fieldError={errors.lastName}
        rules={{
          required: true,
          validate: {
            isEmpty,
          },
        }}
      />
      <ThemeTextField
        control={control}
        label="Email"
        name="email"
        fieldError={errors.email}
        rules={{
          required: true,
          validate: {
            isEmpty,
          },
        }}
      />
      <ThemeTextField
        control={control}
        label="Sujet"
        name="subject"
        fieldError={errors.subject}
        rules={{
          required: true,
          validate: {
            isEmpty,
          },
        }}
      />
      <ThemeTextField
        control={control}
        label="Message"
        name="message"
        fieldError={errors.message}
        rows={6}
        rules={{
          required: true,
          validate: {
            isEmpty,
          },
        }}
      />
      <ThemeButton type="submit">
        Envoyer
      </ThemeButton>
    </form>
  );
}
