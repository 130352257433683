import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import MainLayout from '../../theme/layouts/MainLayout';
import useMessages from '../../services/hooks/Messages';
import ThemeButton from '../../theme/components/ThemeButton';

const useStyle = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function NotFound() {
  const classes = useStyle();
  const messages = useMessages();
  return (
    <MainLayout>
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="h1">{messages.entities.notFound.title}</Typography>
          <Typography variant="caption" paragraph>{messages.entities.notFound.subtitle}</Typography>
          <ThemeButton to="/">{messages.entities.notFound.backHome}</ThemeButton>
        </div>
      </div>
    </MainLayout>
  );
}
