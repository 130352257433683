import useMessages from './hooks/Messages';

export default function errorResolver(type: string, message?: string) {
  const messages = useMessages();
  let error;
  switch (type) {
    case 'validate':
      switch (message) {
        case 'string':
          error = messages.errors.form.string;
          break;
        default:
          error = messages.errors.form.error;
      }
      break;
    case 'required':
      error = messages.errors.form.required;
      break;
    case 'isEmpty':
      error = messages.errors.form.required;
      break;
    case 'isEmail':
      error = messages.errors.form.email;
      break;
    default:
      error = messages.errors.form.error;
  }
  return error;
}
