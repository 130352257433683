import { Fade, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useOnComponentMounted } from '../../lib/ReactHooksAlias';
import MainLayout from '../../theme/layouts/MainLayout';
import ProductCard from './components/ProductCard';
import { ProductType } from '../../types/ProductType';
import ProductApi from '../../api/ProductApi';
import Loader from '../../theme/components/Loader';
import useMessages from '../../services/hooks/Messages';

export default function Products() {
  const messages = useMessages();

  const [products, setProducts] = useState<ProductType[]>([]);

  useOnComponentMounted(() => {
    ProductApi.fetchProducts().then((fetchedProducts: ProductType[]) => {
      setProducts(fetchedProducts);
    });
  });

  return (
    <MainLayout>
      <Typography variant="h2" component="h1">{messages.products.title}</Typography>
      { (products.length > 0)
        ? (
          <Grid container spacing={2}>
            {products.map((product: ProductType) => (
              <Fade in key={product.id} timeout={1000}>
                <Grid item xs={12} sm={6} md={3} xl={3}>
                  <ProductCard product={product} key={product.id} />
                </Grid>
              </Fade>
            ))}
          </Grid>
        )
        : <Loader />}
    </MainLayout>
  );
}
