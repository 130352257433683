import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import PicturePaper from '../../../theme/components/PicturePaper';
import { GalleryItemType } from '../../../types/GalleryType';

type Props = {
  galleryItem?: GalleryItemType;
};

const useStyle = makeStyles((theme: Theme) => ({
  paper: {
    height: '30vh',
    border: 'none',
  },
  mainPaper: {
    height: '35vh',
    border: 'none',
  },
  infoFirst: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  infoLast: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  editButton: {
    margin: `${theme.spacing(2)}px 0`,
  },
}));

export default function GalleryItem({ galleryItem }: Props) {
  const classes = useStyle(useTheme());

  return (
    <PicturePaper
      className={classes.mainPaper}
      url={`${galleryItem?.attributes?.image?.data?.attributes?.url}`}
    />
  );
}
