import Translation from './Translation';

const fr: Translation = {
  signIn: {
    title: 'Connexion',
  },
  home: {
    title: 'Abat-jour sur mesure',
    description1: 'Un éclairage chaleureux donne de la personnalité à votre décor. ',
    description2: 'L’éclairante vous guide dans le choix des matériaux, '
      + 'l’accord des couleurs, les finitions, la taille et la forme de l’abat-jour. ',
    description3: 'Pour une harmonie idéale dans votre pièce, '
      + 'rien n’est laissé au hasard. Offrez-vous un abat-jour personnalisé. '
      + 'Conseil et devis gratuit en contactant Sylvie. ',
    description4: 'Bonne visite.',
  },
  products: {
    title: 'Créations',
  },
  gallery: {
    title: 'Galerie',
  },
  about: {
    title: 'A propos',
  },
  form: {
    email: 'Email',
    password: 'Mot de passe',
    product: {
      covering: 'Habillage',
      dimensions: 'Dimensions',
      electrification: 'Életcrification',
      name: 'Nom',
      mainPicture: 'Image principale',
      miniPicture: 'Image de galerie',
      pictures: 'Liste des images',
      shape: 'Forme',
      title: 'Nouveau Produit',
      text: 'Description',
    },
  },
  entities: {
    notFound: {
      title: '404',
      subtitle: 'Cette page n\'existe pas',
      backHome: 'Retourner à l\'accueil',
    },
    product: {
      add: 'Ajouter un produit',
      covering: 'Habillage',
      delete: 'Supprimer le produit',
      dimensions: 'Dimensions',
      edit: 'Modifier le produit',
      electrification: 'Életcrification',
      presentation: 'Image de présentation',
      shape: 'Forme',
    },
    contact: {
      button: 'Contact',
      date: 'Date',
      from: 'De',
      subject: 'Sujet',
    },
  },
  actions: {
    connect: 'se connecter',
    save: 'Enregistrer',
    seeMore: 'Voir plus',
  },
  success: {
    default: 'Succès',
    add: {
      default: 'Ajouté',
      product: 'Produit ajouté',
    },
    edit: {
      default: 'Modifié',
      product: 'Produit modifié',
    },
    delete: {
      product: 'Produit supprimé',
    },
  },
  errors: {
    default: 'Une erreur est survenue',
    form: {
      email: 'Veuillez renseigner un email valide',
      error: 'Il y a une erreur dans ce champ',
      required: 'Champ requis',
      string: 'Entrez du text',
    },
    http: {
      '/badrequest/default': 'Les données fournies sont invalides',
      '/badrequest/file': 'Le fichier fourni est invalide',
      '/forbidden/default': 'Vous n\'êtes pas autorisé à effectuer cette action',
      '/unauthorized/default': 'Vous n\'êtes pas connecté',
      '/unauthorized/invalid/credentials': 'Email ou mot de passe invalide',
      '/notfound/default': 'L\'élément est inexistant',
      '/notfound/product': 'Produit inexistant',
      '/notfound/gallery': 'Image inexistante',
      '/conflict/default': 'Action impossible pour cette valeur',
      '/conflict/product/name': 'Il existe déjà un produit associé à ce nom',
      '/error/default': 'Une erreur s\'est produite',
    },
  },
};

export default fr;
