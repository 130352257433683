import React, { useState, useCallback, ReactNode } from 'react';
import { NotificationType } from '../../types/NotificationType';

type Props = {
  children: ReactNode;
};

export type NotificationContextType = {
  notification: NotificationType | undefined;
  addError: (name: string, message?: string) => void;
  addSuccess: (name: string, message?: string) => void;
  addWarning: (name: string, message?: string) => void;
  addInfo: (name: string, message?: string) => void;
  removeNotification: () => void;
};

export const NotificationContext = React.createContext<NotificationContextType>({
  notification: undefined,
  addError: () => {},
  addSuccess: () => {},
  addWarning: () => {},
  addInfo: () => {},
  removeNotification: () => {},
});

export default function NotificationProvider({ children }: Props) {
  const [notification, setNotification] = useState<NotificationType>();

  const removeNotification = () => {
    setNotification((prevState: NotificationType | undefined) => (prevState && { ...prevState, active: false }));
  };

  const addError = (
    name: string,
    message?: string,
  ) => setNotification({
    name, message, type: 'error', active: true,
  });
  const addSuccess = (
    name: string,
    message?: string,
  ) => setNotification({
    name, message, type: 'success', active: true,
  });
  const addWarning = (
    name: string,
    message?: string,
  ) => setNotification({
    name, message, type: 'warning', active: true,
  });
  const addInfo = (
    name: string,
    message?: string,
  ) => setNotification({
    name, message, type: 'info', active: true,
  });

  const contextValue = {
    notification,
    addError: useCallback(
      (name, message) => addError(name, message),
      [],
    ),
    addSuccess: useCallback(
      (name, message) => addSuccess(name, message),
      [],
    ),
    addWarning: useCallback(
      (name, message) => addWarning(name, message),
      [],
    ),
    addInfo: useCallback(
      (name, message) => addInfo(name, message),
      [],
    ),
    removeNotification: useCallback(() => removeNotification(), []),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}
