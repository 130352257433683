import React from 'react';
import { Icon, makeStyles, Typography } from '@material-ui/core';
import { ProductType } from '../../../types/ProductType';
import useMessages from '../../../services/hooks/Messages';
import ThemeButton from '../../../theme/components/ThemeButton';

type Props = {
  product: ProductType;
  setOpen: (value: boolean) => void;
};

const useStyle = makeStyles(() => ({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  title: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  iconRoot: {
    textAlign: 'center',
  },
  line: {
    display: 'flex',
  },
  typoWithBreak: {
    whiteSpace: 'pre-line',
  },
}));

export default function ProductInfo({ product, setOpen }: Props) {
  const classes = useStyle();
  const messages = useMessages();
  return (
    <div>
      <Typography variant="h2" component="h1" className={classes.title}>
        {product.attributes.name}
      </Typography>
      <Typography variant="body1" component="p" paragraph>
        {product.attributes.text}
      </Typography>
      {product.attributes.dimensions && (
        <div>
          <Typography variant="subtitle1" component="span">
            {messages.entities.product.dimensions}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.typoWithBreak}
            paragraph
          >
            {product.attributes.dimensions}
          </Typography>
        </div>
      )}
      {product.attributes.shape && (
        <div>
          <Typography variant="subtitle1" component="span">
            {messages.entities.product.shape}
          </Typography>
          <Typography variant="body2" component="p" paragraph>
            {product.attributes.shape}
          </Typography>
        </div>
      )}
      {product.attributes.covering && (
        <div>
          <Typography variant="subtitle1" component="span">
            {messages.entities.product.covering}
          </Typography>
          <Typography variant="body2" component="p" paragraph>
            {product.attributes.covering}
          </Typography>
        </div>
      )}
      {/* Don't show electrification */}
      {/* {product.electrification && ( */}
      {/*  <div> */}
      {/*    <Typography variant="subtitle1" component="span"> */}
      {/*      {messages.entities.product.electrification} */}
      {/*    </Typography> */}
      {/*    <Typography variant="body2" component="p" paragraph> */}
      {/*      {product.electrification} */}
      {/*    </Typography> */}
      {/*  </div> */}
      {/* )} */}
      <Typography className={classes.line} paragraph>
        Fait &#9995; en &nbsp;
        <Icon classes={{ root: classes.iconRoot }}>
          <img
            className={classes.imageIcon}
            src="/1F1EB-1F1F7.svg"
            alt="french flag"
          />
        </Icon>
        &nbsp; à Nantes
      </Typography>
      <ThemeButton onClick={() => setOpen(true)} fullWidth>
        {messages.entities.contact.button}
      </ThemeButton>
    </div>
  );
}
