import React from 'react';
import { Link } from 'react-router-dom';
import {
  makeStyles, Theme,
  Typography, useTheme,
} from '@material-ui/core';
import useMessages from '../../../services/hooks/Messages';

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    width: '50%',
    margin: 'auto',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationThickness: '1px',
    },
  },
  divider: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(16),
  },
  line: {
    height: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    width: '100px',
  },
  image: {
    margin: `0 ${theme.spacing(4)}px`,
    width: '30px',
    height: '30px',
  },
}));

type Props = {
  href: string;
};

export default function SeeMoreLink({ href }: Props) {
  const classes = useStyle(useTheme());
  const messages = useMessages();

  return (
    <div className={classes.container}>
      <div className={classes.divider}>
        <div className={classes.line} />
        <img className={classes.image} src="/logos/logo-sigle.png" alt="sigle de l'eclairante" />
        <div className={classes.line} />
      </div>
      <Link to={href} className={classes.link}>
        <Typography variant="h6" component="span">
          {messages.actions.seeMore}
        </Typography>
      </Link>
    </div>
  );
}
