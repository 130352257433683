import { createTheme, responsiveFontSizes } from '@material-ui/core';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontWeight: 400,
    },
    h2: {
      fontWeight: 300,
    },
    h3: {
      fontWeight: 300,
    },
    h4: {
      fontWeight: 300,
    },
    h5: {
      fontWeight: 300,
    },
    h6: {
      fontWeight: 300,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    button: {
      fontWeight: 'bolder',
    },
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: '#222222',
      light: '#444444',
      dark: '#000000',
      contrastText: '#ffffff',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
        borderRadius: '4px',
        minWidth: 'unset',
      },
      containedSecondary: {
        backgroundColor: 'transparent',
        color: '#222222',
        border: '2px solid #222222',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#444444',
          borderColor: '#444444',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          backgroundColor: '#eeeeee',
        },
        body: {
          margin: 'auto',
          paddingTop: '32px',
          maxWidth: '1200px',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'transparent',
          '@media (max-width: 1280px)': {
            padding: '16px',
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
