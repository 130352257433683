import React from 'react';
import MainLayout from '../../theme/layouts/MainLayout';
import HomeTile from './components/HomeTile';
import ProductsHomeTile from './components/ProductsHomeTile';
import GalleryHomeTile from './components/GalleryHomeTile';
import AboutHomeTile from './components/AboutHomeTile';

export default function Home() {
  return (
    <MainLayout>
      <HomeTile imgUrl="/images/home.jpg" />
      <ProductsHomeTile />
      <GalleryHomeTile />
      <AboutHomeTile />
    </MainLayout>
  );
}
