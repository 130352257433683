import React, { ReactNode, useState } from 'react';
import {
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  img: {
    zIndex: -1000,
    top: 0,
    left: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: theme.spacing(2),
    transition: 'all .6s',
  },
  imgWBorder: {
    zIndex: -1000,
    top: 0,
    left: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
    transition: 'all .6s',
  },
  border: {
    borderRadius: theme.spacing(4),
    border: `${theme.spacing(2)}px double ${theme.palette.primary.main}`,
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
    margin: theme.spacing(2),
  },
}));

type Props = {
  url?: string;
  children?: ReactNode;
  className?: string;
  border?: boolean;
};

export default function PicturePaper({
  url, children, className, border,
}: Props) {
  const classes = useStyle();
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <div className={`${className} ${classes.root} ${border && classes.border}`}>
      <img
        alt="product"
        src={url}
        loading="lazy"
        onLoad={() => setLoaded(true)}
        className={border ? classes.imgWBorder : classes.img}
        style={{ opacity: loaded ? 1 : 0 }}
      />
      {children}
    </div>
  );
}
