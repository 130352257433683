import React, { useState } from 'react';
import {
  makeStyles, Theme, useTheme,
} from '@material-ui/core';
import { useOnComponentMounted } from '../../../lib/ReactHooksAlias';
import HomeLink from './HomeLink';
import Loader from '../../../theme/components/Loader';
import SeeMoreLink from './SeeMoreLink';
import { ArticleType } from '../../../types/ArticleType';
import ArticleApi from '../../../api/ArticleApi';
import AboutTile from '../../about/components/AboutTIle';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '80px',
  },
  section: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(20),
  },
}));

export default function AboutHomeTile() {
  const classes = useStyle(useTheme());

  const [articles, setArticles] = useState<ArticleType[]>([]);

  useOnComponentMounted(() => {
    ArticleApi.fetchFirstsProducts()
      .then((result) => setArticles(result));
  });

  return (
    <div className={classes.root}>
      <HomeLink title="A propos" href="about" />
      {(articles.length > 0)
        ? (
          <div className={classes.section}>
            {articles.map((article: ArticleType) => (
              <AboutTile article={article} inHome />
            ))}
          </div>
        )
        : <Loader />}
      <SeeMoreLink href="about" />
    </div>
  );
}
