import React, { ReactNode } from 'react';
import ThemeNavBar from '../components/ThemeNavBar';

type Props = {
  children: ReactNode;
};

const MainLayout = ({ children }: Props) => (
  <>
    <ThemeNavBar />
    <main style={{ flex: '1' }}>
      {children}
    </main>
  </>
);

export default MainLayout;
